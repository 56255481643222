
import user from './user';
import party from './party';
import projectSetting from './project-setting';
import common from './common';

export default [
  user,
  common,
  projectSetting,
  party
];
