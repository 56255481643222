/**
 *  Date    : 2019/11/7
 *  Author  : CastileMan
 *  Declare : user
 */
import Immutable from 'seamless-immutable';

import partyapi from '@/api/partyapi';
import Utils from '@/utils/utils';

export default {
  namespace: 'party',
  state: Immutable({
    partyList: [],
    auditPartyList: {},
    industryCaseId: 0
  }),
  reducers: {
    update: (state, { data, field }) => state.merge({ [field]: data })
  },
  actions: {
    // 获取机构配置列表（分页）
    getPartyList(payload) {
      return {
        type: 'party/update/updatePartyList',
        field: 'partyList',
        promise: partyapi.getPartyList(payload)
          .then(data => {
            return data;
          })
      };
    },
    // 设置partyId
    setPartyId(industryCaseId) {
      return {
        type: 'party/update/industryCaseId',
        field: 'industryCaseId',
        data: industryCaseId
      };
    },
    // 审核机构
    auditParty(payload) {
      return {
        promise: partyapi.auditParty(payload)
      };
    }
  }
};
