import Immutable from 'seamless-immutable';

import defaultSetting from '@/configs/defaultSetting';

export default {
  namespace: 'projectSetting',
  state: Immutable({
    sideBarWidth: 220,
    headerHeight: 56,
    isSmall: false,
    hasTab: false,
    tabHeight: 50,
    containerPadding: true,
    headerRouters: [{
      name: '新增行业产品',
      path: '/main/addcompany'
    }]
  }),
  reducers: {
    update: (state, { data, field }) => state.merge({ [field]: data }),
    toggleSmallType: ((state, { data }) => {
      return state.merge({
        isSmall: data.isSmall,
        sideBarWidth: data.isSmall ? defaultSetting.MIN_SIDEBAR_WIDTH : defaultSetting.SIDE_BAR_WIDTH
      });
    })
  },
  actions: {
    toggleSmallType(isSmall) {
      return {
        type: 'projectSetting/toggleSmallType',
        data: {
          isSmall
        }
      };
    },
    updateContainerPadding(padding) {
      return {
        type: 'projectSetting/update/containerPadding',
        field: 'containerPadding',
        data: padding
      };
    }
  }
};
