/**
 *  Date    : 2019/9/4
 *  Author  : CastileMan
 *  Declare : 常用hooks
 */
import { useState, useEffect, useCallback, useRef } from 'react';
import shortid from '@wtdc/shortid';
import { useSetState } from 'ahooks';

import { useActions, useSelector } from '@/utils/redux';
import { useLocationChange } from '@/utils/router';

/**
 * 用于刷新数据，通过调用 setRefreshKey 来更改 refreshKey，
 * 从而触发获取数据的 effect钩子 再执行一次，达到刷新数据的目的
 *
 * @returns [refreshKey, updateRefreshKey]
 */
export function useRefreshKey() {
  // key for refresh
  const [refreshKey, setRefreshKey] = useState(null);

  const updateRefreshKey = useCallback(() => {
    setRefreshKey(shortid());
  }, []);

  return [refreshKey, updateRefreshKey];
}

/**
 * 对数据的id进行匹配
 */
export function useDataMatcher(id) {
  const cacheRef = useRef(id);
  const matcherRef = useRef({
    isMatch: cacheRef.current === id,
    update: (_id) => {
      cacheRef.current = _id;
      matcherRef.current.isMatch = cacheRef.current === _id;
    }
  });

  useEffect(() => {
    matcherRef.current.isMatch = cacheRef.current === id;
  }, [id]);

  return matcherRef.current;
}

/**
 * 缓存search参数到redux
 * @param key
 * @param defaultOptions
 * @returns {{setSearchOptions: setSearchOptions, searchOptions: object, resetSearchOptions: resetSearchOptions}}
 */
function useSearchOptions(key, defaultOptions) {
  const cachedOptions = useRef(null);
  if(!cachedOptions.current) {
    if(defaultOptions) {
      cachedOptions.current = Object.assign({}, BASE_SEARCH, defaultOptions);
    } else {
      cachedOptions.current = BASE_SEARCH;
    }
  }
  const searchActions = useActions((actions) => actions.common);
  const searchOptions = useSelector((state) => state.common[key]);

  function setSearchOptions(value) {
    searchActions.setSearchOperation(key, {
      ...cachedOptions.current,
      ...searchOptions,
      ...value
    });
  }

  function resetSearchOptions() {
    searchActions.resetSearchOperations(key);
  }

  return {
    searchOptions: searchOptions || cachedOptions.current,
    setSearchOptions,
    resetSearchOptions
  };
}

const BASE_SEARCH = {
  numPerPage: 10,
  currentPage: 0
};

/**
 * 缓存search参数到redux
 * @returns {{setSearchOptions: setSearchOptions, searchOptions: object, resetSearchOptions: resetSearchOptions}}
 * @param defaultOptions
 */

function useStateSearchOptions(defaultOptions) {
  const [searchOptions, setSearchOptions] = useSetState({
    currentPage: 1,
    numPerPage: 10,
    ...defaultOptions
  });

  function resetSearchOptions(options) {
    setSearchOptions({
      currentPage: 1,
      numPerPage: 10,
      ...defaultOptions,
      ...options
    });
  }

  return {
    searchOptions,
    setSearchOptions,
    resetSearchOptions
  };
}

export {
  useActions,
  useSelector,
  useLocationChange,
  useSearchOptions,
  useStateSearchOptions
};

