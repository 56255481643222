/**
 *  Date    : 2020/12/3
 *  Author  :luyao
 *  Declare : PRIVATE_DOMAIN_PERMISSIONS RAYS私域目录
 */

const PRIVATE_DOMAIN_PERMISSIONS = [
  {
    iconType: 'ma',
    id: 3,
    invalid: false,
    parentId: 0,
    title: '分析',
    path: '/data/analysis',
    typeCode: 'OVERVIEW',
    children: [
      {
        id: 3,
        invalid: false,
        parentId: 2,
        path: '/data/analysis/event',
        title: '事件分析'
      },
      {
        id: 3,
        invalid: false,
        parentId: 2,
        path: '/data/analysis/export',
        title: '明细数据'
      }
    ]
  },
  {
    iconType: 'ma',
    id: 4,
    invalid: false,
    parentId: 0,
    title: '项目',
    path: '/data/project',
    typeCode: 'OVERVIEW',
    children: [
      {
        id: 3,
        invalid: false,
        parentId: 2,
        path: '/data/project/production',
        title: '产品概览'
      },
      {
        id: 3,
        invalid: false,
        parentId: 2,
        path: '/data/project/project',
        title: '项目列表'
      }
    ]
  },
  {
    iconType: 'ma',
    id: 2,
    invalid: false,
    parentId: 0,
    title: '元数据',
    path: '/data/manage',
    typeCode: 'OVERVIEW',
    children: [
      {
        id: 3,
        invalid: false,
        parentId: 2,
        path: '/data/manage/schema',
        title: '数据接入管理',
        typeCode: 'DATA_OVERVIEW'
      },
      {
        id: 4,
        invalid: false,
        parentId: 2,
        path: '/data/manage/metadata',
        title: '元数据管理',
        typeCode: 'DATA_OVERVIEW'
      }
    ]
  }
];

export default PRIVATE_DOMAIN_PERMISSIONS;
