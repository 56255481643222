import React from 'react';
import Loadable from 'react-loadable';
import { message, Spin } from 'antd';

import { createRouter } from '@/utils/router';
import * as Cookie from '@/utils/cookie';
import PAGE from '@/constants/PAGE';

import App from './App';
import userapi from '@/api/userapi';

export default createRouter({
  getComponent: () => App,
  onEnter: async (nextState, history, cb) => {
    const { location, match, query } = nextState;
    const cookie = Cookie.getCookie();
    const isMatch = match && match.isExact;
    // 已登录
    if(query.name && query.pwd) {
      userapi.login({
        username: decodeURIComponent(query.name),
        password: query.pwd
      })
        .then(res => {
          Cookie.saveCookie(res.token);
          message.success('登录成功');
        })
        .catch(() => {
          history.replace(PAGE.LOGIN);
        })
        .finally(() => {
          cb();
        });
    } else if(cookie) {
      // index route
      if(isMatch) {
        history.replace(PAGE.HOME);
      }
      cb();
    } else {    // 未登录
      if(location.pathname.startsWith('/main') || isMatch) {
        history.replace(PAGE.LOGIN);
      }
      cb();
    }
  },
  getChildRoutes: () => [
    {
      path: '/login',
      component: Loadable({
        loader: () => import('./login'),
        loading: () => <Spin />
      })
    },
    {
      path: '/main',
      component: Loadable({
        loader: () => import('./pages'),
        loading: () => <Spin />
      })
    },
    {
      component: Loadable({
        loader: () => import('./404'),
        loading: () => <Spin />
      })
    }
  ]
});
