import axiosServices from './request';

const UserApi = axiosServices('/platform');

export default {
  // 登录
  login: UserApi('post', 'user/login'),
  //刷新token
  logout: UserApi('post', 'user/loginOut'),
  // 获取用户信息
  getUserInfo: UserApi('get', 'user/getInfo')
};
