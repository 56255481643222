/**
 *  Date    : 2019/11/7
 *  Author  : CastileMan
 *  Declare : user
 */
import Immutable from 'seamless-immutable';

import userApi from '@/api/userapi';
import Utils from '@/utils/utils';

import permissions from './menus';

export default {
  namespace: 'user',
  state: Immutable({
    userInfo: {}
  }),
  reducers: {
    update: (state, { data, field }) => state.merge({ [field]: data })
  },
  actions: {
    // 获取用户信息
    getUserInfo() {
      return {
        type: 'user/update/updateUserInfo',
        field: 'userInfo',
        promise: userApi.getUserInfo()
          .then(data => {
            return data;
          })
      };
    },
    // 登录
    login(payload) {
      return {
        promise: userApi.login(payload)
      };
    }
  }
};
