
export const isDevelopment = process.env.NODE_ENV !== 'production';

export const BASE_API_ROOT = window.location.origin;

// 系统角色
const systemCode = 'mono-weapp-web';

const config = {
  isDevelopment,
  systemCode,
  systemCodeText: 'ERP系统',
  COOKIE: `${systemCode}_token`,
  PRODUCTION_COOKIE: `${systemCode}_production_id`,
  ossConfig: {    // OSS配置
    fileServerOrigin: `https://oss.5rs.me`,      // 文件服务器域名
    ossFileRoute: 'oss/uploadfe/',    // 文件存放的目录路径
    ossClientConfig: {
      region: 'oss-cn-hangzhou',
      accessKeyId: 'LTAIEBaJ3cjoYBfE',
      accessKeySecret: 'up612Lm5tFePcyQBWe8u5vSZJdifSo',
      bucket: task => ((task.convert && task.isConvertWithoutURLType)
        ? 'whlg-oss-001'
        : 'whlg-oss-002'),
      endpoint: `https://oss.5rs.me`,
      cname: true,
      secure: true
    }
  },
  iconfontCommonURL: '//at.alicdn.com/t/font_1497591_qi9llkqj9k.js',    // PC端通用字体图标库链接
  iconfontURL: '//at.alicdn.com/t/font_1542801_kdyfn2gn11i.js'     // ERP字体图标库链接
};

export default config;
