
const Utils = {
  /**
   * Flat Map 根据相应的字断
   * @param arr 源数组
   * @param step 层级
   * @param clear 是否删除原层级数据
   * @returns {[]|*[]}
   */
  flatMap(arr, step = 'children', clear = false) {
    const flatArr = [];
    const iterate = function(item) {
      flatArr.push(item);
      if(item[step]) {
        item[step].forEach(v => {
          iterate(v);
        });
        if(clear) {
          delete item[step];
        }
      }
    };
    if(!arr || !(arr instanceof Array)) {
      return [];
    } else {
      arr.forEach(iterate);
      return flatArr;
    }
  },

  params2json(params = '', slice = '&') {
    return params.split(slice).reduce((acc, item) => {
      let arr = item.split('=');
      return item ? { ...acc, ...{ [arr[0]]: arr[1] }} : acc;
    }, {});
  },
  getFileExt(fileName = '') {
    const index = fileName.lastIndexOf('.');
    const regExp = new RegExp('^[A-Za-z0-9]+$');
    if(index > 0 && regExp.test(fileName.slice(index + 1))) {
      fileName = fileName.toLowerCase();
      return fileName.substring(index + 1);
    }
    return '';
  }
};

export default Utils;

