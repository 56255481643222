import axiosServices from './request';

const PlatformApi = axiosServices('/platform');

export default {
  // 获取机构配置列表（分页）
  getPartyList: PlatformApi('post', 'industry/listPageIndustryCase'),
  getConfigType: PlatformApi('get', 'industry/getConfigType'),
  // 获取印码场景
  getSceneList: PlatformApi('get', 'industry/getSceneListByIndustryCaseId'),
  // 新增印码场景
  addIndustryCaseScene: PlatformApi('post', 'industry/addIndustryCaseScene'),
  // 创建行业产品第一步
  createIndustryCase: PlatformApi('post', 'industry/createIndustryCase'),
  // 获取行业产品（第一步、第二步、第三步都有）
  getIndustryCaseById: PlatformApi('get', 'industry/getIndustryCaseById'),
  // 获取标签列表查询
  getLabelList: PlatformApi('get', 'label/getLabelList'),
  // 标签分组-新增
  saveLabelType: PlatformApi('post', 'label/saveLabelType'),
  // 标签分组-删除
  deleteLabelType: PlatformApi('get', 'label/deleteLabelType'),
  // 标签-新增
  saveLabel: PlatformApi('post', 'label/saveLabel'),
  //  标签-删除
  deleteLabel: PlatformApi('get', 'label/deleteLabel'),
  //修改行业第一步
  modifyIndustryCase: PlatformApi('post', 'industry/modifyIndustryCase'),
  // 修改第二步
  setFunctionalConfig: PlatformApi('post', 'industry/setFunctionalConfig'),
  // 设置皮肤
  setSkinConfig: PlatformApi('post', 'industry/setSkinConfig'),
  //审核机构
  auditParty: PlatformApi('post', 'party/auditParty'),
  // 续费
  recharge: PlatformApi('post', 'party/recharge'),
  //设置机构分成比例
  setDivide: PlatformApi('post', 'party/setDivide'),
  // 获取机构修改时机构详情
  getPartyInfo: PlatformApi('get', 'party/getInfo'),
  // 修改机构基础信息
  modifyParty: PlatformApi('post', 'party/modifyParty'),
  //获取菜单
  getPermissions: PlatformApi('get', 'industry/getPermissions'),
  // 删除
  deleteParty: PlatformApi('post', 'party/deleteParty'),
  // 根据id查印码场景配置
  getCaseSceneById: PlatformApi('get', 'industry/getCaseSceneById'),
  //删除印码场景
  deleteIndustryCaseScene: PlatformApi('post', 'industry/deleteIndustryCaseScene'),
  //删除印码场景某一项
  deleteOptionById: PlatformApi('get', 'industry/deleteOptionById'),

  // 旧版
  //添加自定义场景
  customizeScene: PlatformApi('post', 'party/customizeScene'),
  //创建机构
  createParty: PlatformApi('post', 'party/createParty'),
  //获取机构核心功能配置详情
  getPartyConfig: PlatformApi('get', 'party/getPartyConfig'),
  //设置功能性配置

  //获取功能性配置
  getFunctionalConfig: PlatformApi('get', 'party/getFunctionalConfig'),
  // 获取皮肤设置
  getSkinConfig: PlatformApi('get', 'party/getSkinConfig'),
  //获取机构审核列表(分页)
  getAuditPartyList: PlatformApi('post', 'party/getAuditPartyList'),

  //核心功能配置-获取配置相关列表
  getMainConfig: PlatformApi('get', 'party/getMainConfig')
};