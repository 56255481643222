import React, { useEffect } from 'react';
import { useActions } from '@/utils/hooks';
import { Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';

import zhCN from 'antd/lib/locale/zh_CN';

import './App.scss';

export default function App(props) {
  const actions = useActions(({ common }) => ({ common }));
  useEffect(() => {
    actions.common.setUploadConfig();
  }, []);

  return (
    <ConfigProvider locale={zhCN}>
      <Switch>
        {props.children}
      </Switch>
    </ConfigProvider>

  );
}

